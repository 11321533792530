.comment_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
  justify-content: space-between;
}

.comment_name {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.9916px;
  line-height: 18px;
  color: #5b6173;
  width: 40%;
}

.comment {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.9916px;
  line-height: 18px;
  color: #9a9b9e;
  text-align: start;
  width: 50%;
}

.like_icon {
  cursor: pointer;
}
