.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.container {
  width: 100%;
}

.body_container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.body_container * {
  box-sizing: border-box;
}

.post_container {
  width: 100%;
  padding: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gratitudewall {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gratitudewall_column {
  background-clip: padding-box;
}

.load_posts {
  margin-bottom: 20px;
  cursor: pointer;
  color: #0abf53;
  text-align: center;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
}

@media screen and (max-width: 500px) {
  .post_container {
    padding: 0.6rem;
  }
}
