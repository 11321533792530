.fullpost {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 1.3rem;
}

.fullpost_media {
  max-width: 40%;
  align-items: center;
}

.fullpost_image {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.fullpost_image img {
  max-width: 100%;
  max-height: 480px;
}

.fullpost_image video {
  max-width: 100%;
  max-height: 480px;
}

.fullpost_content {
  width: 25%;
  background: #fdfdfd;
  border: 0.932773px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_container {
  display: flex;
  flex-direction: row;
  padding: 0.4rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.user_profilePic {
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.user_profilePic img {
  width: 100%;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}

.user_info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1rem;
}

.user_name {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1rem;
  color: #5b6173;
  cursor: pointer;
}

.user_timestamp {
  padding-top: 0.2rem;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #9a9b9e;
}

.post_text {
  width: 90%;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.9916px;
  line-height: 18px;
  color: #5b6173;
}

.hr_line {
  margin-top: 0.7rem;
}

.box_container {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding-top: 0.4rem;
}

.box {
  display: flex;
  flex-direction: row;
  width: auto;
  padding-right: 1rem;
  align-items: center;
  cursor: pointer;
}

.box_text {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.9916px;
  line-height: 18px;
  color: #b7b7b7;
  padding-left: 0.3rem;
}

.post_comments {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 1rem;
}

.post_comments::-webkit-scrollbar {
  width: 5px;
  background-color: whitesmoke;
  display: none;
}

.post_comments::-webkit-scrollbar-thumb {
  background-color: #5b6173;
  border-radius: 10px;
  display: none;
}

.load_more {
  cursor: pointer;
  width: 90%;
  margin-top: 0.5rem;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #9a9b9e;
}

.blank {
  display: none;
  cursor: none;
}

.line_divide {
  width: 90%;
  padding-top: 1rem;
}

.people_mentioned {
  width: 90%;
  justify-content: center;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #5b6173;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mentions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.3rem;
}

.mentions::-webkit-scrollbar {
  width: 5px;
  background-color: whitesmoke;
}

.mentions::-webkit-scrollbar-thumb {
  background-color: #5b6173;
  border-radius: 10px;
}

.redirect_link {
  text-decoration: underline;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1rem;
  text-align: center;
  color: #0abf53;
  margin: 3rem;
}

@media screen and (max-width: 700px) {
  .fullpost {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .fullpost_media {
    max-width: 90%;
  }

  .fullpost_image {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .fullpost_content {
    width: 90%;
  }

  .user_name {
    padding-top: 0.4rem;
  }
}
