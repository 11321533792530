.share_modal {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 54px;
  background: #fdfdfd;
  border: 0.701805px solid #e3e3e3;
  box-sizing: border-box;
  box-shadow: 0px 2.90641px 5.81282px rgba(0, 0, 0, 0.12);
  border-radius: 4.49057px;
  position: absolute;
  left: 4rem;
  z-index: 999;
  transition: ease-in-out;
}

.socialmedia_btn {
  padding: 0.2rem;
  transform: ease-out;
}

.socialmedia_btn :hover {
  z-index: 3000;
  transform: translateY(-10%);
}

.copy_icon {
  color: #5b6173;
  cursor: pointer;
}
