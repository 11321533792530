.mention_container {
  display: flex;
  flex-direction: row;
  padding: 0 0.4rem 0 0.4rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.mention_profilePic {
  width: 3rem;
  height: 3rem;
  align-items: center;
  object-fit: contain;
}

.mention_profilePic img {
  width: 100%;
  height: 3rem;
  border-radius: 50%;
  align-items: center;
}

.mention_info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

.mention_name {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #5b6173;
}

.mention_appr {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.6rem;
  line-height: 1rem;
  color: #9a9b9e;
}
