.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  align-items: center;
  justify-content: center;
}

.modal_view {
  background-color: white;
  border-radius: 20px;
  width: 420px;
  height: 480px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}



.close {
  width: 100%;
  display: block;
  position: relative;
  top: 10px;
  left: 180px;
  cursor: pointer;
}

.modal_top {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_center {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.center_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
}
.modal_bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.brand {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand img {
  max-width: 4rem;
  max-width: 4rem;
}

.tag {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tag img {
  width: 100%;
}

.tag_border {
  width: 100%;
  height: 1.8rem;
  background: #0abf53;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f5f5f5;
  font-size: 1.2rem;
  border-radius: 10px;
  font-family: "PT Sans";
}

.modal_text {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #5b6173;
  padding-bottom: 0.5rem;
}

.title_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: center;
  letter-spacing: 0.2em;
  color: #5b6173;
}

.supertext {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 1.6rem;
  color: #0abf53;
}

.center_text {
  padding-right: 1rem;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #5b6173;
}

.app_link {
  padding: 0.3rem;
}

@media screen and (max-width: 500px) {
  .modal_view {
    width: 80vw;
    height: 80vh;
  }
  .modal_bottom {
    flex-direction: column;
    align-items: center;
  }

  .brand {
    width: 100%;
    padding-bottom: 0.3rem;
  }

  .tag {
    width: 100%;
    padding-bottom: 0.3rem;
  }

  .close {
    width: 100%;
    display: block;
    position: relative;
    top: 10px;
    left: 135px;
    cursor: pointer;
  }
}
