.profile_card {
  font-family: "PT Sans";
  background-color: #fff;
  margin: 2rem 0;
  padding: 8rem 0;
  width: 300px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3%;
}

.profile_img_wrapper {
  width: 100%; 
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.profile_img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.profile_stats {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  width: 100%;
  margin-top: -10px;
}

.profile_stats_item {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.profile_stats_item_header {
  font-weight: bolder;
  font-size: 18px;
}

.profile_stats_item_content {
  font-weight: normal;
  font-size: 16px;
}


.view_profile_btn_div {
  width: 100%; 
  text-align: center;
  margin-bottom: 50px;
}

.view_profile_btn {
    height: 47px;
    width: 150px;
    color: #f5f5f5;
    border-radius: 45.5043px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12.937px;
    line-height: 16px;
    letter-spacing: 0.11em;
    background-color: #0abf53;
}