

#unsubscribe {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

#unsubscribe-mail-icon {
    position: relative;
    left: -30%;
    width: 100px;
}


#unsubscribe #error-div {
    padding-top: 3px;
    margin-top: 5px;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: red;
}

@media screen and (max-width: 400px) {
    #unsubscribe-mail-icon {
        position: relative;
        left: -10%;
        width: 100px;
    }

    #unsubscribe #error-div {
        font-size: 12px;
        line-height: 18px;
        color: red;
    }

    #unsubscribe .sub_title {
        font-size: 14px;
    }
  }

#unsubscribe .title, #unsubscribe .sub_title {
    color: #000;
}   


#unsubcribe .app_btn {
    height: 34px;
    width: 83px;
    background: #fdfdfd;
    border-radius: 32.9906px;
}

