.card {
  /* position: relative; */
  /* min-width: 25%; */
  max-width: 246px;
  background: #ffffff;
  border: 1.12381px solid #ececec;
  box-sizing: border-box;
  box-shadow: -3px 3.37143px 6.74286px rgba(0, 0, 0, 0.12);
  border-radius: 4.49524px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
  margin-right: 1rem;
}

.card_media {
  max-width: 256px;
  min-width: 100%;
  padding: 0.3rem;
}

.card_media_item {
  width: 100%;
  display: flex;
  align-items: center;
}

.card_media_item img {
  max-width: 100%;
}

.card_media_item video {
  max-width: 100%;
}

.card_profilePic {
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.card_profilePic img {
  width: 100%;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}

.card_text {
  width: 100%;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13.4857px;
  line-height: 17px;
  color: #5b6173;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: start;
}

.see_post {
  width: 100%;
  display: flex;
  padding: 0.8rem 0 0.8rem 0.3rem;
}

.post_link {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #0abf53;
}

.card_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: rgba(8, 208, 121, 0.2);
  border: 1.12381px solid rgba(0, 161, 252, 0.15);
  box-sizing: border-box;
  border-radius: 0px 0px 4.49524px 4.49524px;
  justify-content: space-between;
}

.trend {
  width: 58%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trend_text {
  color: #0abf53;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.5rem;
  line-height: 1rem;
}

.hashtag {
  height: 80%;
  width: 90%;
  border: 0.876523px solid #0abf53;
  border-radius: 1.75305px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0abf53;
  text-align: center;
}

.hashtag p {
  padding-top: 4px;
}

.share_btn {
  padding: 0.3rem;
  width: 42%;
  display: flex;
  flex-direction: row;
  background: rgba(8, 209, 125, 0.1);
  border: 1.12381px solid rgba(0, 161, 252, 0.15);
  box-sizing: border-box;
  border-radius: 0px 0px 4.49524px 0px;
  cursor: pointer;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12.4211px;
  line-height: 16px;
  color: rgba(10, 191, 83, 1);
  justify-content: center;
}

.btn_text {
  padding-left: 3px;
}

.popup {
  position: relative;
  bottom: 2rem;
}

@media screen and (max-width: 500px) {
  .card {
    max-width: 100%;
    margin-right: 0;
  }
}
