.media_element {
  width: 100%;
  position: relative;
}

.left_arrow {
  left: 3px;
  bottom: 50%;
  cursor: pointer;
  user-select: none;
  color: #9a9b9e;
  position: absolute;
  opacity: 0;
  transition: opacity 800ms;
}

.right_arrow {
  right: 3px;
  bottom: 50%;
  position: absolute;
  user-select: none;
  color: #9a9b9e;
  cursor: pointer;
  opacity: 0;
  transition: opacity 800ms;
}

.media_element:hover .left_arrow,
.media_element:hover .right_arrow {
  opacity: 1;
}

.video {
  max-width: 100%;
  height: auto;
}
