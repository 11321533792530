.header {
  display: flex;
  background: linear-gradient(102.04deg, #0abf53 9.38%, #05e8b1 95.14%);
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.header_logo {
  display: flex;
}
.logo {
  padding-left: 43px;
  padding-top: 40px;
  padding-bottom: 22px;
}

.title {
  padding-left: 18px;
  padding-top: 38px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 21.4118px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #fdfdfd;
}

.sub_title {
  padding-left: 18px;
  padding-top: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 17.4048px;
  line-height: 21px;

  color: #fdfdfd;
}

.app_btn {
  margin-top: 42px;
  margin-right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  width: 115px;
  background: #f5f5f5;
  border-radius: 45.5043px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12.937px;
  line-height: 16px;
  letter-spacing: 0.11em;
  color: #0abf53;
}

@media screen and (max-width: 550px) {
  .header_logo {
    flex-wrap: nowrap;
  }

  .logo {
    padding-left: 19px;
    padding-top: 38px;
    width: 30px;
  }

  .title {
    padding-left: 12px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #fdfdfd;
  }

  .sub_title {
    padding-top: 3px;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 11.3801px;
    line-height: 18px;
    color: #fdfdfd;
  }

  .app_btn {
    height: 34px;
    width: 83px;
    background: #fdfdfd;
    border-radius: 32.9906px;
  }
}
